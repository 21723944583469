import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface MainState {
  count: number;
  increment: () => void;
  decrement: () => void;
}

export const useMainStore = create<MainState>()(
  persist(
    (set) => ({
      count: 0,
      increment: () => set((state) => ({ count: state.count + 1 })),
      decrement: () => set((state) => ({ count: state.count - 1 })),
    }),
    { name: 'main-storage' } // key of local storage
  )
);

// export const useMainStore = create<MainState>((set) => ({
//   count: 0,
//   increment: () => set((state) => ({ count: state.count + 1 })),
//   decrement: () => set((state) => ({ count: state.count - 1 })),
// }));
